import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Menu from './components/Menu';
import VocabMode from './components/VocabMode';
import ExamMode from './components/ExamMode';
import FlashcardExamMode from './components/FlashcardExamMode';
import TermsMode from './components/TermsMode';

function App() {
  return (
    <div className="App">
      <Header />
      <main className="main-content">
        <Routes>
          <Route path="/" element={<Menu />} />
          <Route path="/vocab" element={<VocabMode />} />
          <Route path="/exam" element={<ExamMode />} />
          <Route path="/flashcard-exam" element={<FlashcardExamMode />} />
          <Route path="/terms" element={<TermsMode />} />
        </Routes>
      </main>
    </div>
  );
}

export default App;

