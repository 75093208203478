import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles.css';

function FlashcardExamMode() {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showAnswer, setShowAnswer] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [totalScore, setTotalScore] = useState(0);
  const [answeredQuestions, setAnsweredQuestions] = useState([]);
  const [examData, setExamData] = useState(null);
  const [selectedExam, setSelectedExam] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showScore, setShowScore] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await fetch('/data/exams.json');
        if (!response.ok) throw new Error('Failed to load exam data');
        const data = await response.json();
        setExamData(data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error loading exam data:', error);
        setError('Failed to load exam data');
        setIsLoading(false);
      }
    };

    loadData();
  }, []);

  const handleExamSelect = (examKey) => {
    setSelectedExam(examKey);
    setCurrentIndex(0);
    setShowAnswer(false);
    setSelectedAnswer(null);
    setTotalScore(0);
    setAnsweredQuestions([]);
  };

  const getLetterGrade = (score, total) => {
    const percentage = (score / total) * 100;
    if (percentage >= 90) return 'A';
    if (percentage >= 80) return 'B';
    if (percentage >= 70) return 'C';
    if (percentage >= 60) return 'D';
    return 'F';
  };

  const handleAnswerSelect = (answer) => {
    setSelectedAnswer(answer);
    const isCorrect = answer === currentQuestion.correct_answer;
    if (isCorrect) {
      setTotalScore(totalScore + 1);
    }
    setAnsweredQuestions([...answeredQuestions, {
      questionIndex: currentIndex,
      isCorrect
    }]);
    setShowAnswer(true);
  };

  const handleNext = () => {
    if (currentIndex < questions.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setSelectedAnswer(null);
      setShowAnswer(false);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      setSelectedAnswer(null);
      setShowAnswer(false);
    }
  };

  const handleRetry = () => {
    setCurrentIndex(0);
    setTotalScore(0);
    setSelectedAnswer(null);
    setShowAnswer(false);
    setAnsweredQuestions([]);
  };

  const handleExit = () => {
    navigate('/');
  };

  if (isLoading) return <div>Loading exam data...</div>;
  if (error) return <div>Error: {error}</div>;

  const examOptions = Object.keys(examData || {});
  const currentExam = selectedExam ? examData[selectedExam] : null;
  const questions = currentExam?.questions || [];
  const currentQuestion = questions[currentIndex];

  return (
    <div className="flashcard-exam-mode">
      <div className="top-bar">
        <div className="score-container">
          {showScore && selectedExam && (
            <div className="score-box">
              <p>Score: {totalScore} / {questions.length}</p>
              <p>Grade: {getLetterGrade(totalScore, questions.length)}</p>
            </div>
          )}
        </div>
        {selectedExam && (
          <button 
            onClick={() => setShowScore(!showScore)} 
            className="toggle-score-button"
          >
            {showScore ? 'Hide Score' : 'Show Score'}
          </button>
        )}
      </div>

      <div className="exam-selector">
        <select 
          value={selectedExam} 
          onChange={(e) => handleExamSelect(e.target.value)}
        >
          <option value="">Select an Exam</option>
          {examOptions.map(examKey => (
            <option key={examKey} value={examKey}>
              {examData[examKey].title}
            </option>
          ))}
        </select>
      </div>

      {selectedExam ? (
        <>
          <div className="flashcard">
            <div className="card-content">
              <h3>{currentQuestion.question}</h3>
              <div className="options">
                {currentQuestion.options?.map((option) => (
                  <button
                    key={option}
                    onClick={() => !showAnswer && handleAnswerSelect(option)}
                    className={`option-btn ${
                      showAnswer
                        ? option === currentQuestion.correct_answer
                          ? 'correct'
                          : selectedAnswer === option
                          ? 'incorrect'
                          : ''
                        : ''
                    }`}
                    disabled={showAnswer}
                  >
                    {option}
                  </button>
                ))}
              </div>
            </div>
          </div>

          <div className="navigation">
            <button 
              onClick={handlePrevious} 
              disabled={currentIndex === 0}
              className="nav-button"
            >
              ← Previous
            </button>
            <span>Question {currentIndex + 1} of {questions.length}</span>
            <button 
              onClick={handleNext}
              disabled={currentIndex === questions.length - 1}
              className="nav-button"
            >
              Next →
            </button>
          </div>
        </>
      ) : (
        <div className="placeholder-message">
          <h3>Select an exam to begin</h3>
        </div>
      )}
    </div>
  );
}

export default FlashcardExamMode; 