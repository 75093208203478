import React, { useState, useEffect } from 'react';
import './styles.css';
import ScoreTracker from '../ScoreTracker';

const VocabMode = () => {
  const [content, setContent] = useState({});
  const [selectedSection, setSelectedSection] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showDefinition, setShowDefinition] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [answeredWords, setAnsweredWords] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [studyMode, setStudyMode] = useState('flashcard'); // 'flashcard' or 'fillblank'
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [showResult, setShowResult] = useState(false);

  useEffect(() => {
    const loadVocabData = async () => {
      try {
        const response = await fetch('/data/vocab.json');
        if (!response.ok) throw new Error('Failed to load vocabulary data');
        const data = await response.json();
        setContent(data);
        setIsLoading(false);
      } catch (err) {
        console.error('Error loading vocabulary data:', err);
        setError(err.message);
        setIsLoading(false);
      }
    };
    loadVocabData();
  }, []);

  const sections = Object.keys(content || {});
  const currentSectionWords = selectedSection ? Object.entries(content[selectedSection] || {}) : [];
  const currentWord = currentSectionWords[currentIndex]?.[1];

  // Get 3 random words from the current section (excluding the current word)
  const getRandomOptions = () => {
    if (!currentWord) return [];
    
    const otherWords = currentSectionWords
      .map(([_, word]) => word.word)
      .filter(word => word !== currentWord.word);
    
    const shuffled = [...otherWords].sort(() => 0.5 - Math.random());
    const randomWords = shuffled.slice(0, 3);
    
    // Add correct answer and shuffle again
    const allOptions = [...randomWords, currentWord.word]
      .sort(() => 0.5 - Math.random());
    
    return allOptions;
  };

  const handleSectionSelect = (section) => {
    setSelectedSection(section);
    setCurrentIndex(0);
    setShowDefinition(false);
    setIsComplete(false);
    setAnsweredWords([]);
    setSelectedAnswer(null);
    setShowResult(false);
  };

  const handleNextWord = () => {
    setAnsweredWords([...answeredWords, {
      word: currentWord.word,
      seen: true,
      correct: studyMode === 'flashcard' || selectedAnswer === currentWord.word
    }]);
    
    if (currentIndex === currentSectionWords.length - 1) {
      setIsComplete(true);
    } else {
      setCurrentIndex(currentIndex + 1);
      setShowDefinition(false);
      setSelectedAnswer(null);
      setShowResult(false);
    }
  };

  const handleAnswerSelect = (answer) => {
    setSelectedAnswer(answer);
    setShowResult(true);
  };

  if (isLoading) return <div>Loading vocabulary data...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="vocab-card">
      <h2>Vocabulary Practice</h2>
      
      {/* Mode Selection */}
      <div className="mode-selector">
        <button 
          className={`mode-btn ${studyMode === 'flashcard' ? 'active' : ''}`}
          onClick={() => setStudyMode('flashcard')}
        >
          Flashcard Mode
        </button>
        <button 
          className={`mode-btn ${studyMode === 'fillblank' ? 'active' : ''}`}
          onClick={() => setStudyMode('fillblank')}
        >
          Fill in the Blank
        </button>
      </div>

      {sections.length > 0 ? (
        <>
          <div className="section-selector">
            <select 
              value={selectedSection} 
              onChange={(e) => handleSectionSelect(e.target.value)}
            >
              <option value="">Select a Vocabulary Unit</option>
              {sections.map(section => (
                <option key={section} value={section}>{section}</option>
              ))}
            </select>
          </div>

          {selectedSection && currentWord && !isComplete ? (
            studyMode === 'flashcard' ? (
              // Flashcard Mode
              <div className="flashcard" onClick={() => setShowDefinition(!showDefinition)}>
                <div className="card-content">
                  {showDefinition ? (
                    <>
                      <p className="definition">{currentWord.definition}</p>
                      <p className="example">Example: {currentWord.example}</p>
                    </>
                  ) : (
                    <h2 className="word">{currentWord.word}</h2>
                  )}
                </div>
              </div>
            ) : (
              // Fill in the Blank Mode
              <div className="fill-blank-mode">
                <p className="sentence">{currentWord.fill_blank}</p>
                <div className="options">
                  {getRandomOptions().map((option, index) => (
                    <button
                      key={index}
                      onClick={() => handleAnswerSelect(option)}
                      className={`option-btn ${
                        showResult
                          ? option === currentWord.word
                            ? 'correct'
                            : selectedAnswer === option
                            ? 'incorrect'
                            : ''
                          : ''
                      }`}
                      disabled={showResult}
                    >
                      {option}
                    </button>
                  ))}
                </div>
                {showResult && (
                  <div className="feedback">
                    {selectedAnswer === currentWord.word ? (
                      <p className="correct-text">Correct!</p>
                    ) : (
                      <p className="incorrect-text">
                        Incorrect. The correct answer is: {currentWord.word}
                      </p>
                    )}
                  </div>
                )}
              </div>
            )
          ) : isComplete ? (
            <div className="completion-message">
              <h3>Unit Complete!</h3>
              <p>
                Score: {answeredWords.filter(w => w.correct).length} / {answeredWords.length}
              </p>
              <button onClick={() => handleSectionSelect(selectedSection)}>
                Review Again
              </button>
            </div>
          ) : (
            <div className="placeholder-message">
              <h3>Select a vocabulary unit to begin studying</h3>
            </div>
          )}

          {currentWord && !isComplete && (
            (studyMode === 'flashcard' || showResult) && (
              <button onClick={handleNextWord} className="next-button">
                Next Word
              </button>
            )
          )}
        </>
      ) : (
        <div className="placeholder-message">
          <h3>No vocabulary content available</h3>
        </div>
      )}
    </div>
  );
};

export default VocabMode; 