import React, { useState, useEffect } from 'react';
import './styles.css';

const TermsMode = () => {
  const [content, setContent] = useState({});
  const [selectedSection, setSelectedSection] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showDefinition, setShowDefinition] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [answeredWords, setAnsweredWords] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadContent = async () => {
      try {
        const response = await fetch('/data/terms.json');
        if (!response.ok) throw new Error('Failed to load terms content');
        const data = await response.json();
        setContent(data);
        setIsLoading(false);
      } catch (err) {
        console.error('Error loading terms content:', err);
        setError(err.message);
        setIsLoading(false);
      }
    };

    loadContent();
  }, []);

  const handleSectionSelect = (section) => {
    setSelectedSection(section);
    setCurrentIndex(0);
    setShowDefinition(false);
    setIsComplete(false);
    setAnsweredWords([]);
  };

  const handleNextWord = () => {
    const terms = Object.entries(content[selectedSection] || {});
    setAnsweredWords([...answeredWords, {
      word: terms[currentIndex][0],
      seen: true
    }]);
    
    if (currentIndex === terms.length - 1) {
      setIsComplete(true);
    } else {
      setCurrentIndex(currentIndex + 1);
      setShowDefinition(false);
    }
  };

  if (isLoading) return <div>Loading terms...</div>;
  if (error) return <div>Error: {error}</div>;

  const sections = Object.keys(content || {});
  const terms = selectedSection ? Object.entries(content[selectedSection] || {}) : [];
  const currentTerm = terms[currentIndex];

  return (
    <div className="vocab-card">
      <h2>Terms Practice</h2>
      
      <div className="section-selector">
        <select 
          value={selectedSection} 
          onChange={(e) => handleSectionSelect(e.target.value)}
        >
          <option value="">Select a Unit</option>
          {sections.map(section => (
            <option key={section} value={section}>{section}</option>
          ))}
        </select>
      </div>

      {selectedSection && currentTerm && !isComplete ? (
        <div className="flashcard" onClick={() => setShowDefinition(!showDefinition)}>
          <div className="card-content">
            {showDefinition ? (
              <p className="definition">{currentTerm[1]}</p>
            ) : (
              <h2 className="word">{currentTerm[0]}</h2>
            )}
          </div>
        </div>
      ) : isComplete ? (
        <div className="completion-message">
          <h3>Unit Complete!</h3>
          <button onClick={() => handleSectionSelect(selectedSection)}>
            Review Again
          </button>
        </div>
      ) : (
        <div className="placeholder-message">
          <h3>Select a unit to begin studying</h3>
        </div>
      )}

      {currentTerm && !isComplete && (
        <button onClick={handleNextWord} className="next-button">
          Next Term
        </button>
      )}
    </div>
  );
};

export default TermsMode;