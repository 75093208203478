import React from 'react';
import { Link } from 'react-router-dom';
import './styles.css';

const Menu = () => {
  return (
    <div className="menu-container">
      <h1>Choose Study Mode</h1>
      <div className="menu-grid">
        <Link to="/vocab" className="menu-card">
          <span className="menu-icon">📚</span>
          <h2>Vocabulary Practice</h2>
          <p>Study vocabulary words with definitions and examples</p>
        </Link>
        
        <Link to="/exam" className="menu-card">
          <span className="menu-icon">📝</span>
          <h2>Exam Mode</h2>
          <p>Take a full practice exam with multiple choice questions</p>
        </Link>

        <Link to="/flashcard-exam" className="menu-card">
          <span className="menu-icon">🗂️</span>
          <h2>Flashcard Exam</h2>
          <p>Practice exam questions one at a time in flashcard format</p>
        </Link>

        <Link to="/terms" className="menu-card">
          <span className="menu-icon">🔬</span>
          <h2>Terms</h2>
          <p>Learn terms and definitions</p>
        </Link>
      </div>
    </div>
  );
};

export default Menu; 