import React, { useState, useEffect } from 'react';
import './styles.css';
import { useNavigate } from 'react-router-dom';

function ExamMode() {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [showResults, setShowResults] = useState(false);
  const [score, setScore] = useState(0);
  const [examData, setExamData] = useState(null);
  const [selectedExam, setSelectedExam] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await fetch('/data/exams.json');
        if (!response.ok) throw new Error('Failed to load exam data');
        const data = await response.json();
        setExamData(data);
        setIsLoading(false);
      } catch (error) {
        console.error('ExamMode: Error loading data:', error);
        setError('Failed to load exam data');
        setIsLoading(false);
      }
    };

    loadData();
  }, []);

  const handleExamSelect = (examKey) => {
    setSelectedExam(examKey);
    setCurrentQuestion(0);
    setSelectedAnswers({});
    setShowResults(false);
    setScore(0);
  };

  const handleAnswerSelect = (questionId, answer) => {
    setSelectedAnswers({
      ...selectedAnswers,
      [questionId]: answer
    });
  };

  const handleSubmit = () => {
    let correctCount = 0;
    const currentExamQuestions = examData[selectedExam].questions;
    
    currentExamQuestions.forEach(question => {
      if (selectedAnswers[question.id] === question.correct_answer) {
        correctCount++;
      }
    });
    setScore(correctCount);
    setShowResults(true);
  };

  const handleRetry = () => {
    setSelectedAnswers({});
    setShowResults(false);
    setScore(0);
    setCurrentQuestion(0);
  };

  const getLetterGrade = (score, total) => {
    const percentage = (score / total) * 100;
    if (percentage >= 90) return 'A';
    if (percentage >= 80) return 'B';
    if (percentage >= 70) return 'C';
    if (percentage >= 60) return 'D';
    return 'F';
  };

  const handleSwitchToFlashcards = () => {
    navigate('/flashcard-exam', { state: { selectedExam } });
  };

  if (isLoading) return <div>Loading exam data...</div>;
  if (error) return <div>Error: {error}</div>;

  const examOptions = Object.keys(examData || {});
  const currentExam = selectedExam ? examData[selectedExam] : null;
  const questions = currentExam?.questions || [];

  return (
    <div className="exam-mode">
      <h2>Exam Practice</h2>

      <div className="exam-selector">
        <select 
          value={selectedExam} 
          onChange={(e) => handleExamSelect(e.target.value)}
        >
          <option value="">Select an Exam</option>
          {examOptions.map(examKey => (
            <option key={examKey} value={examKey}>
              {examData[examKey].title}
            </option>
          ))}
        </select>
      </div>

      {selectedExam ? (
        showResults ? (
          <div className="results">
            <h3>Exam Results</h3>
            <div className="score-summary">
              <p>Score: {score} out of {questions.length} ({Math.round((score / questions.length) * 100)}%)</p>
              <p>Grade: {getLetterGrade(score, questions.length)}</p>
            </div>
            
            <div className="answer-review">
              <h4>Review Your Answers:</h4>
              {questions.map((question, index) => {
                const userAnswer = selectedAnswers[question.id];
                const isCorrect = userAnswer === question.correct_answer;
                
                return (
                  <div key={question.id} className={`answer-item ${isCorrect ? 'correct' : 'incorrect'}`}>
                    <p><strong>Question {index + 1}:</strong> {question.question}</p>
                    <p>Your answer: {userAnswer}</p>
                    {!isCorrect && <p>Correct answer: {question.correct_answer}</p>}
                  </div>
                );
              })}
            </div>

            <div className="result-actions">
              <button onClick={handleRetry} className="retry-button">
                Retake Exam
              </button>
              <button onClick={handleSwitchToFlashcards} className="flashcard-button">
                Study with Flashcards
              </button>
            </div>
          </div>
        ) : (
          <>
            <div className="questions">
              {questions.map((question, index) => (
                <div key={question.id} className="question-card">
                  <p className="question-text">
                    <span className="question-number">{index + 1}. </span>
                    {question.question}
                  </p>
                  <div className="options">
                    {question.options.map((option) => (
                      <label key={option} className="option">
                        <input 
                          type="radio" 
                          name={`question-${question.id}`} 
                          value={option}
                          checked={selectedAnswers[question.id] === option}
                          onChange={() => handleAnswerSelect(question.id, option)}
                        />
                        <span>{option}</span>
                      </label>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <div className="exam-controls">
              <button 
                onClick={handleSubmit}
                className="submit-button"
              >
                Submit Exam
              </button>
            </div>
          </>
        )
      ) : (
        <div className="placeholder-message">
          <h3>Select an exam to begin</h3>
        </div>
      )}
    </div>
  );
}

export default ExamMode; 